import { keyboardTrapper } from '../autoload/keyboardTrapper';

let trapper = new keyboardTrapper({ parent: '.sidenav' });
let sidenav = jQuery('#mySidenav');

jQuery('.burger-menu, .close-menu').on('click', function () {
  sidenav.toggleClass('open');
  if (sidenav.hasClass('open')) {
    jQuery('body').css('overflow', 'hidden');
    jQuery('.burger-menu').each(function() {
      jQuery(this).attr('aria-expanded', true);
    });
    trapper.init();
  } else {
    jQuery('body').css('overflow', 'auto');
    jQuery('.burger-menu').each(function() {
      jQuery(this).attr('aria-expanded', false);
    });
    trapper.dismiss();
  }
});

jQuery('body').on('click', function(e) {
  if (
    !jQuery('.header .burger-menu')[0].contains(e.target) &&
    !sidenav[0].contains(e.target) &&
    sidenav.hasClass('open')
  ) {
    sidenav.removeClass('open');
    jQuery('body').css('overflow', 'auto');
    jQuery('.burger-menu').each(function() {
      jQuery(this).attr('aria-expanded', false);
    });
    trapper.dismiss();
  }
});
