import Swiper from 'swiper/bundle';
import Chart from 'chart.js/auto';

export default {
  init() {
    // JavaScript to be fired on all pages
    jQuery(function () {
      jQuery('.match').matchHeight();
      jQuery('.match-two').matchHeight();
      jQuery('.match-three').matchHeight();
      jQuery('.match-read-more').matchHeight();
      jQuery('.equal').matchHeight({ byRow: false });
      jQuery('.equal-content').matchHeight({ byRow: false });
      jQuery('.contact-equal').matchHeight({
        byRow: false,
        target: jQuery('.form-section-blue'),
      });
    });

    require('../components/sidenav');

    let charts = jQuery('.chart');
    if (charts.length) {
      const chartColors = [
        '#8bcc4d',
        '#0aaa5b',
        '#258071',
        '#0b4451',
      ];

      charts.each(function() {
        let container = jQuery(this);
        let data = container.find('.chart__data .point');
        let area = container.find('.chart__area');
        let legend = container.find('.chart__legend');
        if (data.length && area.length) {
          let dataArr = [];
          data.each(function() {
            let point = jQuery(this);
            let name = point.find('.name').text();
            let value = point.find('.value').text();
            dataArr.push({ name, value });
          });

          new Chart(area[0], {
            type: 'pie',
            data: {
              labels: dataArr.map(point => point.name),
              datasets: [
                {
                  data: dataArr.map(point => point.value),
                  backgroundColor: chartColors,
                },
              ],
            },
            options: {
              responsive: true,
              maintainAspectRatio: true,
              plugins: {
                legend: {
                  display: false,
                },
                tooltip: {
                  callbacks: {
                    label: function(item) {
                      return item.parsed + '%'
                    },
                  },
                },
              },
            },
          });

          if (legend.length) {
            let legendArr = dataArr.map((point, i) => {
              return `
                <p class="d-flex align-items-center justify-content-start mt-3">
                  <span class="swatch" style="background:${chartColors[i]};"></span>
                  <span class="text">${point.name} ${point.value}%</span>
                </p>
                `
            });
            legend.append(legendArr.join(''));
          }

          data.remove();
        }
      });
    }

    if (document.querySelectorAll('.testimonial-slider').length > 0) {
      new Swiper('.testimonial-slider', {
        slidesPerView: 1,
        loop: true,
        spaceBetween: 30,
        autoplay: {
          pauseOnMouseEnter: true,
          disableOnInteraction: false,
        },
        pagination: {
          el: '.swiper-pagination-testimonials',
          clickable: true,
        },
      });
    }

    if (document.querySelectorAll('.gallery-slider').length > 0) {
      new Swiper('.gallery-slider', {
        slidesPerView: 1,
        speed: 1000,
        loop: true,
        spaceBetween: 16,
        autoplay: {
          delay: 5000,
          pauseOnMouseEnter: true,
          disableOnInteraction: false,
        },
        pagination: {
          el: '.swiper-pagination',
          clickable: true,
        },
        breakpoints: {
          575: {
            slidesPerView: 'auto',
          },
        },
      });
    }

    if (document.querySelectorAll('.pricing-slider').length > 0) {
      new Swiper('.pricing-slider', {
        slidesPerView: 1,
        loop: true,
        spaceBetween: 16,
        autoplay: {
          pauseOnMouseEnter: true,
          disableOnInteraction: false,
        },
        pagination: {
          el: '.swiper-pagination',
          clickable: true,
        },
        breakpoints: {
          1366: {
            slidesPerView: 4,
          },
          991: {
            slidesPerView: 3,
          },
          575: {
            slidesPerView: 2,
          },
        },
      });
    }

    if (document.querySelectorAll('.companies-slider').length > 0) {
      new Swiper('.companies-slider', {
        slidesPerView: 1,
        loop: true,
        autoplay: {
          pauseOnMouseEnter: true,
          disableOnInteraction: false,
        },
        pagination: {
          el: '.swiper-pagination',
          clickable: true,
        },
        breakpoints: {
          576: {
            slidesPerView: 2,
            spaceBetween: 15,
          },
          768: {
            slidesPerView: 3,
            spaceBetween: 20,
          },
          992: {
            slidesPerView: 4,
            spaceBetween: 40,
          },
          1200: {
            slidesPerView: 5,
            spaceBetween: 50,
          },
        },
      });
    }

    if (document.querySelectorAll('.products-slider').length > 0) {
      new Swiper('.products-slider', {
        slidesPerView: 1,
        spaceBetween: 100,
        loop: false,
        autoplay: {
          pauseOnMouseEnter: true,
          disableOnInteraction: false,
        },
        pagination: {
          el: '.swiper-pagination-products',
          clickable: true,
        },
        breakpoints: {
          768: {
            slidesPerView: 2,
            spaceBetween: 40,
          },
          1200: {
            slidesPerView: 2,
            spaceBetween: 60,
          },
          1367: {
            slidesPerView: 3,
            spaceBetween: 80,
          },
        },
      });
    }

    if (document.querySelectorAll('.swiper-usps').length > 0) {
      new Swiper('.swiper-usps', {
        slidesPerView: 1,
        spaceBetween: 100,
        loop: false,
        autoplay: {
          pauseOnMouseEnter: true,
          disableOnInteraction: false,
        },
        pagination: {
          el: '.swiper-pagination-usps',
          clickable: true,
        },
        breakpoints: {
          768: {
            slidesPerView: 2,
            spaceBetween: 40,
          },
          1200: {
            slidesPerView: 2,
            spaceBetween: 60,
          },
          1367: {
            slidesPerView: 3,
            spaceBetween: 80,
          },
        },
      });
    }
  },
  finalize() {
    // JavaScript to be fired on all pages, after page specific JS is fired
  },
};
